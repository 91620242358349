/**
 * Submenu styles. These typically should live inside the morphing dropdown, but
 * can also be used otherwise through the site.
 */

.sub-navigation {
  &.-multicol {
    display: flex;
    // padding: ($gutter / 2) 0;
  }

  [class^="text-hl-"] {
    text-align: left;
    margin-bottom: 0;
    margin-top: $gutter;
    padding-left: $gutter * 1;
    padding-right: $gutter * 1;
    white-space: nowrap;
  }
}

.sub-navigation-list {
  padding: ($gutter / 2);
  display: block;
  min-width: $logo-height;
}

.sub-navigation-item {
  display: block;
  text-align: left;

  & + & {
    margin-top: $gutter * 0.25;
  }

  &.is-active {
    .sub-navigation-link {
      color: $hpz-stone-grey;
      background: $hpz-beige;
    }
  }

  .icon {
    color: $hpz-old-silver;
    font-size: 0.8em;
    margin-right: 0.5em;
    top: -0.1em;
  }
}

.sub-navigation-link {
  display: block;
  width: 100%;
  white-space: nowrap;
  color: $hpz-stone-grey;
  text-decoration: none;
  padding: 0.5em 0.75em;
  border-radius: $border-radius;

  transition: all $duration-fast $easing;

  &:hover {
    background: $hpz-beige;
    color: $hpz-stone-grey;
  }
}

.sub-navigation-col {
  flex-grow: 1;

  & + & {
    margin-left: -$gutter / 2;
  }
}
