/**
 * Dropdown
 */

.dropdown {
  position: absolute;
  left: 50%;
  z-index: $zindex-dropdown;

  background: $dropdown-background;
  border-radius: $dropdown-border-radius;
  box-shadow: 0 .5em 4em rgba($hpz-stone-grey, .1),
              0 .25em 1em rgba($hpz-stone-grey, .1),
              0 .25em 1em rgba(#000, .05);

  transform: translateX(-50%);
  transition: all $duration-medium $easing;

  &:not(.without-caret){
    &::before {
      content: '';
      transition: inherit;

      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      right: auto;
      transform: translateX(-50%) translateY(60%) rotate(45deg);
      height: $dropdown-caret-size;
      width: $dropdown-caret-size;
      background: $dropdown-background;
      border-radius: $dropdown-border-radius;
    }
  }

  &.without-caret {
    // border-top-left-radius: 0;
    // border-top-right-radius: 0;
  }
}

.dropdown-menu {
  min-width: 20em;
}

.dropdown-content {
  padding: $gutter;
  min-width: 20em;
}


/**
 * Hide the dropdowns within container elements with class `has-dropdown` and
 * make it visible on hovering.
 */

.has-dropdown {
  perspective: 500px;
  z-index: 1;

  .dropdown {
    visibility: hidden;
    opacity: 0;
    transform-origin: top left;
    transform: translateX(-50%) translateY(.25em) rotateX(-10deg);
  }

  &:hover {
    .dropdown {
      visibility: visible;
      opacity: 1;
      transform: translateX(-50%) translateY(0) rotateX(0);
    }
  }
}
