.module-grid {
  .module-text + & {
    margin-top: -1 * $spacing;
  }
}

.grid-items {
  --columns: 3;

  display: grid;
  gap: $gutter;
  grid-template-columns: repeat(var(--columns), 1fr);
}

.grid-item {
}

.grid-tile {
  background: $beige;
  border-radius: $border-radius;
  padding: $gutter;
}
