.contact-info {
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

.contact-label {
  display: inline-block;
  width: 3.5em;
}

.contact-value {
}

.training-not-available {
  position: relative;

  .training-placeholder {
    width: 100%;
    background: $hpz-old-silver;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $gutter * 2;
    text-align: center;

    span {
      font-weight: 600;
      font-size: $font-size-3xl;
      color: $white;
    }
  }
}
