/**
 * Quote
 */

 .quote {
  p {
    font-family: $font-family-serif;
    font-weight: 400;

    &::before {
      content: "„";
      margin-left: -.375em;
    }
    &::after {
      content: "“";
    }
  }

  &::after {
    content: "—\2009" attr(cite);
    font-size: $font-copy-m;
    font-family: $font-family-sans;
    font-weight: 600;
    color: $hpz-old-silver;
  }
}
