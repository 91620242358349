/**
 * Footer
 */

.site-footer {
  padding-top: $gutter;
  padding-bottom: $gutter;

  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-xs){
      display: block;

      .copyright {
        margin-top: 1em;
      }
    }
  }
}