/**
 * Footer navigation item
 */

 .footer-navigation-list {
  align-items: flex-start;

  @media screen and (max-width: $breakpoint-xs){
    display: block;
  }
}

.footer-navigation-item {
  &:not(:last-child) {
    padding-right: $gutter;
  }
}

.footer-navigation-link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}
