.imageset-wrapper {
  position: relative;
  overflow: hidden;
}


.imageset {
  background-color: var(--dominant-color);
  width: 100%;

  &.lazyloaded {
    background-color: none;
  }
}


.imageset-with-placeholder {
  width: 100%;
  z-index: 1;
  opacity: 0;

  position: absolute;
  top: 0;

  transition: opacity 200ms ease-in-out;

  &.lazyloaded {
    opacity: 1;
    position: relative;

    & + .imageset-placeholder {
      position: absolute;
      top: 0;
    }
  }
}

.imageset-placeholder {
  background-color: var(--dominant-color);
  padding-top: var(--ratio);

  width: 100%;
  display: block;

  z-index: 0;
}
