.module-hint {
  width: 100%;
  max-width: 60em;

  margin-left: auto;
  margin-right: auto;
}

.module-hint.-left {
  margin-left: 0;
}

.module-hint.-right {
  margin-right: 0;
}
