@import "../config";

[data-template="post"],
[data-template="training"] {
  .hero-border-wrapper {
    max-width: 76em;
    left: 50%;
    transform: translateX(-50%);
  }

  .module-title,
  .module-text {
    margin-left: auto;
    margin-right: auto;
    max-width: 70em;
  }
}

.post-info {
  margin-top: $gutter;

  color: $hpz-old-silver;

  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

  .btn {
    margin-left: $gutter;
  }
}

.post-body .module:first-child {
  margin-top: $spacing;
}

.post-navigation {
}

.post-navigation-btn {
  position: absolute;
  top: 3.125rem;
  z-index: 100;

  &.for-previous {
    right: 0;
  }

  &.for-next {
    left: 0;
  }

  @media screen and (max-width: $breakpoint-l) {
    top: -3rem;
  }

  @media screen and (max-width: $breakpoint-2xs) {
    top: -3.5rem;
  }

  @media screen and (max-width: $breakpoint-xl) {
    &.for-previous {
      right: 0.5rem;
    }

    &.for-next {
      left: 0.5rem;
    }
  }
}
