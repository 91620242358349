/**
 * Helper mixin for the color-based variations for our topics.
 */

@mixin colored($attribute, $scope: false, $class: true) {
  @each $colors in $topiccolors {
    $name: nth($colors, 1);
    $color: nth($colors, 2);

    @if $scope == page {
      [data-theme="#{$name}"] & {
        #{$attribute}: #{$color};
      }
    } @else if $class == false {
      .#{$name} & {
        #{$attribute}: #{$color};
      }
    } @else {
      &-#{$name} {
        #{$attribute}: #{$color};
      }
    }
  }
}


/**
 * Color tweaking – tint
 *
 * @param color to tweak
 * @param percentage to tint
 */

@function tint($color, $percentage){
  @return mix($color, #ffffff, 100 - $percentage);
}


/**
 * Color tweaking – shade
 *
 * @param color to tweak
 * @param percentage to shade
 */

@function shade($color, $percentage){
  @return mix($color, #000000, 100 - $percentage);
}
