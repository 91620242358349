.module-team {
  padding: 0 ($gutter * 1);

  @media screen and (max-width: $breakpoint-s) {
    padding: 0 $gutter;
  }
}

.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.member {
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 12em;
  height: 12em;
  padding: 1em;

  transition: all 100ms ease-in-out;
  transform-origin: center center;

  @media screen and (max-width: $breakpoint-s) {
    width: 10em;
    height: 10em;
  }

  &.-hidden {
    opacity: 0.25;
    filter: saturate(50%);
    pointer-events: none;
  }

  // &.-zoomed {
  //   width: 20em;
  //   height: 20em;

  //   cursor: zoom-out;
  // }

  &:not(.-zoomed):not(.-hidden):hover {
    z-index: 2;

    .avatar {
      transform: scale(1.1);
    }
  }

  .dropdown {
    top: 80%;
    align-self: flex-end;
  }

  .avatar {
    transition: all ease-in-out 200ms;
  }
}

.member-desc {
  padding: $gutter / 2;
  white-space: nowrap;

  [class^="text-hl"] {
    margin-bottom: 0;
  }
}

.team-filter {
  margin-top: $gutter;
  padding: $gutter;
  padding-top: $gutter * 2;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  bottom: 0;
  z-index: 10;

  * + * {
    margin-left: 0.5em;
  }

  span {
    margin-right: 1em;
    margin-bottom: 0;
    display: block;
  }

  @media screen and (max-width: $breakpoint-xs) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: $gutter / 2;
    padding-top: $gutter * 2;
    margin-top: 0;

    [class^="text-hl"] {
      width: 100%;
      text-align: center;
      margin-bottom: 0.5em;
    }

    .divider,
    .team-resort {
      display: none;
    }
  }
}
