/**
 * Breadcrumb styles
 */

.breadcrumb-navigation {
  margin-top: $spacing * 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $gutter;

  @media screen and (max-width: $breakpoint-s) {
    display: block;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.breadcrumb-navigation-list {
  @media screen and (max-width: $breakpoint-s) {
    display: block;
  }
}

.breadcrumb-navigation-title {
  padding-left: $gutter;

  @media screen and (max-width: $breakpoint-s) {
    padding-left: 0;
  }
}

.breadcrumb-navigation-item {
  padding: 0 1em;

  @media screen and (max-width: $breakpoint-s) {
    padding-left: 1.25em;
  }

  &:not(.is-active)::after {
    content: "";
    display: block;
    background: url(../img/icons/chevron-right.svg) no-repeat center center;
    background-size: 100%;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    opacity: 0.25;

    @media screen and (max-width: $breakpoint-s) {
      display: none;
    }
  }

  &::before {
    @media screen and (max-width: $breakpoint-s) {
      content: "";
      display: block;
      background: url(../img/icons/arrow-right.svg) no-repeat center center;
      background-size: 100%;
      width: 0.9em;
      height: 0.9em;
      position: absolute;
      top: 0.75em;
      left: 0;
      transform: translateY(-50%);
      opacity: 0.4;
    }
  }
}

.breadcrumb-navigation-link {
  color: currentColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    @include colored("color", page);
  }

  .is-active & {
    font-weight: 600;

    &:hover {
      text-decoration: none;
      color: currentColor;
    }
  }
}
