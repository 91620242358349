/**
 * Stylesheet configuration
 *
 * @author Jakob Ploens <m@jakobploens.com>
 * @link   www.hpz-scharnhausen.de
 */

/* --- 1 Colors ----------------------------------------- */

/**
 * Primary colors
 */
$hpz-marine: #133845; // hsb(196, 72, 27)
$hpz-grass: #669d24; // hsb(87, 77, 62)
$hpz-forrest: #226b20; // hsb(118, 70, 42)
$hpz-tangerine: #f39200; // hsb(36, 100, 95)
$hpz-sunshine: #d2c515; // hsb(50, 90, 82)

/**
 * Secondary colors
 */
$hpz-old-silver: #878575; // hsb(53, 13, 53)
$hpz-stone-grey: #4c5250; // hsb(160, 7, 32)
$hpz-beige: #e4dfbc; // hsb(53, 18, 89)

/**
 * Tertiary colors - only for web purposes. Those are not
 * affected to our brand, therefore they are not prefixed.
 */
$black: #222b2f;
$white: #ffffff;
$grey-light: #dae4e9;
$grey-base: #9babb4; // #8a8a8a
$grey-dark: #596a73; // #4a4a4a

$beige: #f3efd8;

// List of primary colors for easier usage
$topiccolors: (
  marine $hpz-marine,
  grass $hpz-grass,
  forrest $hpz-forrest,
  tangerine $hpz-tangerine,
  sunshine $hpz-sunshine,
  oldsilver $hpz-old-silver,
  stonegrey $hpz-stone-grey,
  beige $hpz-beige,
);

// UI Colors, borrowed from tailwindcss v1.9.0
$yellow-light: #f6e05e; // yellow-400
$yellow-normal: #d69e2e; // yellow-600
$yellow-dark: #975a16; // yellow-800

$red-light: #feb2b2; // red-300
$red-normal: #e53e3e; // red-600
$red-dark: #9b2c2c; // red-800

$blue-light: #90cdf4; // blue-300
$blue-normal: #3182ce; // blue-600
$blue-dark: #2c5282; // blue-800

$green-light: #9ae6b4; // green-300
$green-normal: #d69e2e; // green-600
$green-dark: #276749; // green-800

/* --- 2 Fonts ------------------------------------------ */

/**
 * Families
 */
$font-family-sans: "freight-sans-pro", "FreightSans Pro", sans-serif;
$font-family-serif: "freight-text-pro", "FreightText Pro", serif;

/**
 * Base sizing
 */
$font-size: 18px;
$font-line-height: 1.4;
$font-weight: 500;

/**
 * Sizes
 */
$font-size-xs: 0.75rem; // 12
$font-size-s: 0.875rem; // 14
$font-size-base: 1rem; // 16
$font-size-l: 1.125rem; // 18
$font-size-xl: 1.24rem; // 20
$font-size-2xl: 1.5rem; // 24
$font-size-3xl: 1.875rem; // 30
$font-size-4xl: 2.25rem; // 36
$font-size-5xl: 2.5rem; // 40
$font-size-6xl: 3rem; // 48

$font-copy-xs: $font-size-s;
$font-copy-s: $font-size-base;
$font-copy-m: $font-size-l;
$font-copy-l: $font-size-xl;
$font-copy-xl: $font-size-2xl;

$font-hl-xs: $font-size-s;
$font-hl-s: $font-size-l;
$font-hl-m: $font-size-xl;
$font-hl-l: $font-size-3xl;
$font-hl-xl: $font-size-5xl;

$font-hl-m-lh: 1;
$font-hl-l-lh: 1.2;
$font-hl-xl-lh: 1.125;

// Letter spacing
$font-kerning-s: 0.025em;
$font-kerning-m: 0.05em;
$font-kerning-l: 0.075em;
$font-kerning-xxl: 0.1em;

// Weights
$font-weight-normal: 500;
$font-weight-bold: 600;

/* --- 3 Sizes ------------------------------------------ */

/**
 * Gutter is based on font size.
 * Usually this should be something like 1.42857… (this
 * is calculated by translating mm to pt in print), but as
 * we only have one valid unit in digital design, we stick
 * to even numbers.
 */
$gutter: 1.4rem;

$logo-height: $gutter * 10;
$header-border-height: $gutter * 0.2;
$header-height: ($gutter * 6) + $header-border-height;
$hero-height: $gutter * 20;

$grid-gutter: $gutter * 2;
$spacing: $gutter * 2;

$border-radius: 0.2rem;

$dropdown-caret-size: 0.6em;
$dropdown-background: $white;
$dropdown-width: $gutter * 10;
$dropdown-border-radius: $border-radius;

/* --- 4 Animation -------------------------------------- */

$easing: ease-in-out;

$duration-fast: 100ms;
$duration-base: 150ms;
$duration-medium: 200ms;
$duration-slow: 400ms;

/* --- 5 Z-Index ---------------------------------------- */

$zindex-page: 1;
$zindex-header: 2;
$zindex-carousel: 7;
$zindex-dropdown: 8;
$zindex-main-navigation: 9;
$zindex-dialog: 10;

/* --- 6 Breakpoints ------------------------------------ */

$breakpoint-xxl: 1440px;
$breakpoint-xl: 1340px;
$breakpoint-l: 1160px;
// 1060px;
$breakpoint-m: 970px;
// 860px
$breakpoint-s: 780px;
$breakpoint-xs: 660px;
$breakpoint-2xs: 560px;
$breakpoint-3xs: 360px;

$breakpoint-hide-nav: $breakpoint-l;
