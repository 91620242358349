.files-wrapper {
}

.files-title {
  margin-bottom: $gutter;
}

.files-list {
  display: grid;
  gap: $gutter;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
  }
}

.file-item {
}

.file-link {
  display: flex;
  align-items: center;
  height: 100%;

  color: inherit;
  text-decoration: none;
  padding: 0.75em 1em;
  border-radius: $border-radius;
  background: $beige;

  transition: all $duration-base $easing;

  &:hover {
    box-shadow: 0 1em 2em rgba($black, 0.11), 0 0.33em 1em rgba($black, 0.08);
    transform: scale(1.01);
    z-index: 10;

    .file-figure-preview {
      &.-image {
        opacity: 0;
      }
    }
    .file-figure-download {
      opacity: 1;
    }
  }
}

.file-figure {
  flex-flow: 0;
  flex-shrink: 0;
  margin-right: 1em;
  width: 2.5em;
  height: 2.5em;
  overflow: hidden;

  > span {
    width: 100%;
    height: 100%;

    transition: opacity 100ms ease-in-out;
  }
}

.file-figure-download,
.file-figure-preview.-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $hpz-marine;
  color: $beige;
  border-radius: 100%;

  .icon {
    display: block;
    width: 1.25em;
    height: 1.25em;
  }
}

.file-figure-preview.-image {
  display: block;
}

.file-figure-download {
  opacity: 0;
  z-index: 1;
}

.file-figure-preview {
  // opacity: 1;
  transform: translateY(-100%);
}

.file-text {
}

.file-details {
  display: block;
  margin-bottom: 0.25em;
}

.file-title {
  display: block;
}

.file-caption {
  display: block;
  margin-top: 0.25em;
  color: $hpz-old-silver;
}
