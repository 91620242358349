/**
 * Button Component
 */

.btn {
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;

  border-radius: $border-radius;
  outline: none;
  border: none;
  background: transparent;
  padding: 1em 3em;
  font: inherit;
  white-space: nowrap;
  text-decoration: none;

  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

  transition: all $duration-base $easing;

  &[disabled],
  &.is-disabled {
    pointer-events: none;
    opacity: 0.4;
    box-shadow: none !important;

    .icon {
      opacity: 0.5;
    }
  }

  &.small {
    font-size: $font-size-base;
  }
}

.btn-small {
  padding: 0.5em 0.75em;
}

.btn-round {
  border-radius: 100%;
  padding: 0;
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;

  &:not(.btn-outline) {
    box-shadow: 0 0.125em 0.25em rgba($black, 0.1);
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0.2em 0.4em rgba($black, 0.1);
  }

  .btn-label {
    display: none;
  }

  .btn-icon {
    .icon {
      width: 1.25em;
      height: 1.25em;
      top: -0.25em;
    }
  }

  &.small {
    font-size: 66%;

    .icon {
      width: 1.75em;
      height: 1.75em;
      top: -0.33em;
    }
  }
}

/**
 * Button styles
 */

.btn-full {
  width: 100%;
  text-align: center;
}

.btn-primary {
  background: $hpz-marine;
  color: $white;
}

.btn-secondary {
  background: $hpz-tangerine;
  color: $white;
}

.btn-tertiary {
  background: $hpz-beige;
  color: $black;
}

.btn-outline {
  background: $white;
  border: 2px solid currentColor;

  &.btn-primary {
    color: $hpz-marine;
  }

  &.btn-secondary {
    color: $hpz-tangerine;
  }

  &.btn-tertiary {
    color: $hpz-beige;
  }
}

.btn.-do-open-nav {
  cursor: pointer;
  font-weight: 600;

  transition: all 150ms ease-in-out;

  color: $white;
  align-self: flex-start;
  margin-left: auto;

  border-radius: $border-radius;
  background: rgba($hpz-stone-grey, 0.3);
  padding: 0.5em 1em;
  font-size: 1.25em;

  margin-top: 2%;

  @media screen and (max-width: $breakpoint-2xs) {
    padding: 0.25em 0.5em;
  }

  .btn-label {
    margin-left: 0.5em;
  }
}

.btn.-do-close-nav {
}

/**
 * Button Text
 */

.btn-text {
  font-size: 85%;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: $font-kerning-s;
  border-radius: 0;
  z-index: 1;
  padding: 0;

  text-decoration: none !important;
  border-bottom: 1px solid currentColor;
  @include colored("color", page);

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: -1em;
    top: -0.25em;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    z-index: -1;
    padding: 0.1em 1em 0.5em;

    transform: scale(0.75);

    transition: inherit;
  }

  &:hover {
    border-bottom-color: transparent;

    &::after {
      background: rgba($hpz-stone-grey, 0.1);
      transform: scale(1);
    }
  }
}

/**
 * Button Contents
 */

.btn-label,
.btn-icon {
  display: inline-block;
  vertical-align: middle;
  transition: inherit;
}

.btn-icon {
  .icon {
    width: 0.8em;
    height: 0.8em;
    top: -0.05em;

    transition: inherit;
  }
}
