/**
 * Icon
 */

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;

  svg {
    width: 100%;
    height: 100%;
    stroke: currentColor;
    -webkit-transform: translate3d(0,0,0);
  }
}

.icon-load {
  animation-name: rotate;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}