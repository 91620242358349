/**
 * Flexbox Grid
 */

.grid {
  display: flex;
  flex-flow: row wrap;
  margin-left: $grid-gutter * -1;
  margin-right: $grid-gutter * -1;
}

.grid > * {
  flex: 1 0 5em;
  margin-left: $grid-gutter;
  margin-right: $grid-gutter;
}

/**
 * Narrow grid with half gutter.
 */

.grid-narrow {
  margin-left: $grid-gutter * -0.5;
  margin-right: $grid-gutter * -0.5;
}

.grid-narrow > * {
  margin-left: $grid-gutter * 0.5;
  margin-right: $grid-gutter * 0.5;
}

/**
 * Most Narrow grid with quarter gutter.
 */

.grid-tight {
  margin-left: $grid-gutter * -0.25;
  margin-right: $grid-gutter * -0.25;
}

.grid-tight > * {
  margin-left: $grid-gutter * 0.25;
  margin-right: $grid-gutter * 0.25;
}

/**
 * Grid with wider items
 */

.grid.with-wider-items {
  flex: 1 0 20em;
}
