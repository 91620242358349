/**
 * Site wide header with logo and navigation. The header's border color is based
 * on the body class `page-[color]`, as well as the main headlines and other stuff.
 */

.site-header {
  width: 100%;
  height: $header-height;
  z-index: $zindex-header;

  color: $white;
  background: url(../img/header-bg.jpg) no-repeat center center;
  background-size: cover;
  border-bottom: $header-border-height solid $hpz-marine;
  padding-top: $gutter;

  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  @include colored("border-bottom-color", page);

  @media screen and (max-width: $breakpoint-xs) {
    height: $header-height * 0.8;
    padding-top: $gutter * 0.5;
  }

  @media screen and (max-width: $breakpoint-2xs) {
    height: $header-height * 0.6;
    padding-top: $gutter * 0.5;
  }
}

/**
 * Logo within the header has a fixed width.
 */

.site-header-logo {
  display: inline-block;
  width: $logo-height;
  margin-right: $gutter;
  flex-shrink: 0;

  @media screen and (max-width: $breakpoint-xs) {
    width: 11em;
  }

  @media screen and (max-width: $breakpoint-2xs) {
    width: 8em;
  }
}

/**
 * Wrapper for our navigational items.
 */

.site-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $header-height - $gutter;
  width: 100%;

  @media screen and (max-width: $breakpoint-hide-nav) {
    height: auto;
    display: none;

    /**
     * Be careful – the following is duplicate code from components/dropdown
     */

    position: absolute;
    top: 0;
    left: 50%;
    width: 95%;
    z-index: $zindex-dropdown;
    margin-top: -0.175em;
    color: $hpz-stone-grey;

    background: $dropdown-background;
    border-radius: $dropdown-border-radius;
    box-shadow: 0 0.5em 4em rgba($hpz-stone-grey, 0.1),
      0 0.25em 1em rgba($hpz-stone-grey, 0.1), 0 0.25em 1em rgba(#000, 0.05);

    transform: translateX(-50%);
    transition: all $duration-base $easing;

    .has-open-nav & {
      display: flex;
    }
  }
}

/**
 * Quicklinks
 */

.site-nav-service {
  order: 1;

  @media screen and (max-width: $breakpoint-hide-nav) {
    order: 2;
  }

  .service-navigation-list {
    align-items: flex-end;
    justify-content: flex-end;

    @media screen and (max-width: $breakpoint-hide-nav) {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1em;
    }
  }
}

/**
 * Main navigation
 */

.site-nav-main {
  order: 2;

  @media screen and (max-width: $breakpoint-hide-nav) {
    order: 1;
  }

  .main-navigation-list {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

/**
 * Skip to main content link
 */
.site-skip-to-content {
  position: absolute;
  left: -99999rem;
  top: auto;
  overflow: hidden;
  width: 1px;
  height: 1px;
  z-index: -9999;

  &:focus,
  &:active {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    left: 50%;
    top: 0;
    transform: translatex(-50%);
    padding: $gutter;
    width: auto;
    height: auto;
    z-index: $zindex-main-navigation;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    color: $white;
    background: rgba($black, 0.4);
  }
}

/**
 * Toggle navigation on small screens
 */

.-do-open-nav,
.-do-close-nav {
  display: none;

  @media screen and (max-width: $breakpoint-hide-nav) {
    display: inline-block;
  }
}

.-do-close-nav {
  align-self: center;
  margin: 1em;
}

.has-open-nav {
  @media screen and (max-width: $breakpoint-hide-nav) {
    .main-navigation-list {
      display: block;
    }

    .service-navigation-list {
      margin-top: $gutter;
      font-size: $font-size-l;
    }

    .sub-navigation.-multicol {
      flex-wrap: wrap;
    }

    .sub-navigation-col {
      width: 100%;

      & + .sub-navigation-col {
        margin-left: 0;
        margin-top: 0.5em;
      }
    }

    .sub-navigation [class^="text-hl-"] {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-left: 0.9rem;
      padding-right: 0.9rem;
    }

    .sub-navigation-list {
      margin: 0;
      padding: 0;
      min-width: 0;
    }

    .sub-navigation-item {
      font-size: $font-size-l;
    }

    .sub-navigation-link {
      // padding-left: 1.5rem;

      &::before {
        content: "→";
        color: $hpz-old-silver;
        margin-right: 0.5em;
        font-size: $font-size-base;
      }
    }

    .main-navigation-item,
    .service-navigation-item {
      &.has-dropdown {
        .dropdown {
          visibility: visible;
          opacity: 1;
          transform: none;
          position: relative;
          box-shadow: none;
          margin: 0;
          left: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    .main-navigation-item {
      text-align: left;
      font-size: $font-size-xl;
      padding: 0;
      margin-bottom: 1em;
    }

    .main-navigation-link {
      width: 100%;
      margin-bottom: 0.25em;

      &:hover {
        background: $hpz-beige;
      }

      span {
        padding: 0;

        &::after {
          display: none;
        }
      }
    }

    .service-navigation-search {
      width: 100%;
      margin-bottom: 1em;
    }

    .service-navigation-item:not(.service-navigation-search) {
      flex: 1;
    }
  }
}
