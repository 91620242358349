.table-wrapper {
  max-width: 60em;
}

.table-title {
  margin-bottom: $gutter;
}

.table-caption {
  margin-top: $gutter;
}

.table {
  width: 100%;
  border-spacing: 0;

  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

  th {
    text-align: left;
  }

  th, td {
    padding: .5em .25em;
    border-bottom: 1px solid rgba($hpz-old-silver, .2);
  }

  th {
    border-bottom-color: rgba($hpz-old-silver, .4);

    @include colored('color', page);
  }

  tfoot td {
    font-weight: 600;
  }
}
