/**
 * Global selector
 * 1. Make sure that we use proper box-sizing model.
 * 2. Absolute positioning should always refer to the parent in favour of
 * the root.
 */
* {
  box-sizing: border-box; /* [1] */
  position:   relative;   /* [2] */
  margin:     0;
  padding:    0;
}


/**
 * Root element
 */
html {
  background: $white;
  height:     100%;
}


/**
 * Body
 * 1. Ensures that the footer is always at the very bottom of our page
 */
body {
  min-height: 100vh; /* [1] */
  background: $white;
}