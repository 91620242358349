.module-sponsors {
  &.-full {
    padding-left: $gutter;
    padding-right: $gutter;

    .grid-items {
      gap: $gutter;
    }
  }

  .grid-items {
    gap: $gutter * 2;
  }
}

.sponsor {
  margin-bottom: $gutter * 2;
}

.sponsor-logo {
  --width: 100%;

  height: 10rem;
  padding: $gutter / 2;
  text-align: center;

  a {
    display: block;
  }

  a,
  img,
  .imageset-wrapper {
    height: 100%;
    width: 100%;
  }

  .imageset-wrapper {
    transition: all $duration-base $easing;
  }

  img {
    object-fit: scale-down;
    height: 100%;
    width: var(--width);
  }

  a:hover .imageset-wrapper {
    transform: scale(1.05);
  }

  &.-transparent {
    .imageset-wrapper {
      opacity: 0.75;
      filter: grayscale(100);
    }

    &:hover {
      .imageset-wrapper {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
}

.sponsor-text {
  .text-hl-xs {
    display: block;
    margin-bottom: $gutter / 2;
  }
}

.sponsor-title {
  margin-top: $gutter;
  @include colored("color", page);
}

.sponsor-profile {
  p:first-of-type {
    margin-top: $gutter / 2;
  }
}

.sponsor-description {
  margin-top: $gutter;

  p:first-of-type {
    margin-top: $gutter / 2;
  }
}

.sponsor-posts {
  margin-top: $gutter;

  a {
    text-decoration: none;

    & + a {
      margin-top: $gutter / 2;
    }
  }

  .tile .icon {
    top: 0.25rem;
    float: right;
    color: $hpz-old-silver;
  }
}
