.module-accordeon {
  max-width: 70em;
}

.accordeon-wrapper {
}

.accordeon-title {
  margin-bottom: $gutter;
}

.accordeon {
  border-top: 1px solid $grey-light;
}

.accordeon-item {
  border-bottom: 1px solid $grey-light;
}

.accordeon-button {
  appearance: none;
  background: none;
  border: none;
  font: inherit;
  text-align: left;
  padding: 1em;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:hover {
    background: $beige;
  }
}

.accordeon-button-text {
  > span {
    display: block;
  }
}
.accordeon-item-caption {
  margin-bottom: 0;
  
  [data-open="true"] & {
    @include colored("color", page);
  }
}

.accordeon-button-icon {
  margin-left: 0.5em;
  height: .8em;
  fill: $hpz-stone-grey;
}

[aria-expanded="true"] .vert {
  display: none;
}

.accordeon-content {
  height: 0;
  overflow: hidden;
  transition: height 100ms ease-in-out;
  
  .copytext {
    padding: 1em 2em 2em;
  }
}