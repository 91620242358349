/**
 * All our navigation items are living inside this module. The `navigation`
 * class is ment as overall wrapper for all navigational links and should be
 * used around all navigations.
 */

 .navigation {

}

.navigation-list {
  list-style: none;
  margin: 0;
  display: flex;
}
