html {
  font-size:   $font-size;
  font-family: $font-family-sans;
  font-weight: $font-weight;

  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-variant-ligatures:  common-ligatures;

  @media screen and (max-width: $breakpoint-m) {
    font-size: $font-size - 2;
  }

  @media screen and (max-width: $breakpoint-s) {
    font-size: $font-size - 2;
  }

  @media screen and (max-width: $breakpoint-3xs) {
    font-size: $font-size - 3;
  }
}

body {
  font-size:   100%;
  line-height: $font-line-height;
  color:       $hpz-stone-grey;
}

h1, h2, h3, h4, h5, h6 {
  font-size:   1em;
  font-weight: normal;
  margin:      0;
}

p, ol, ul {
  margin-top: #{$font-line-height}em;
  margin-bottom: #{$font-line-height}em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}