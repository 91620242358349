$news-grid-gutter: $gutter;

.module-news {
  padding-left: $news-grid-gutter;
  padding-right: $news-grid-gutter;
}

.news-grid {
  --columns: 3;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: $news-grid-gutter;

  @media screen and (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
  }
}

.news-pagination {
  margin-top: $spacing;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: $gutter;
    margin-right: $gutter;
    color: $hpz-old-silver;
  }
}
