@import "config";

@import "base/helpers";
@import "base/reset";
@import "base/layout";
@import "base/typography";
@import "base/media";

@import "components/grid";
@import "components/module";
@import "components/wrapper";
@import "components/copytext";
@import "components/page";

@import "components/button";
@import "components/image";
@import "components/icon";

@import "components/avatar";
@import "components/person";
@import "components/dropdown";
@import "components/hero";
@import "components/logo";
@import "components/share";
@import "components/title";
@import "components/navigation";
@import "components/navigation-service";
@import "components/navigation-sub";
@import "components/navigation-main";
@import "components/navigation-footer";
@import "components/quote";
@import "components/table";
@import "components/tile";
@import "components/hint";
@import "components/list";

@import "components/footer";
@import "components/header";
@import "components/contact-teaser";
@import "components/breadcrumb";

@import "blocks/title";
@import "blocks/text";
@import "blocks/text-image";
@import "blocks/team";
@import "blocks/images";
@import "blocks/news";
@import "blocks/files";
@import "blocks/accordeon";
@import "blocks/grid";
@import "blocks/hint";
@import "blocks/list";
@import "blocks/sponsors";

@import "components/post";

@import "shame";

// Core CSS file
@import "../../node_modules/photoswipe/dist/photoswipe.css";
@import "components/photoswipe";
