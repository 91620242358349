.list {
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.list-item {
  // display: inline-block;
  // line-height: 2;
  white-space: nowrap;
  margin: 0.25rem;

  background: $beige;
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius;

  // flex-grow: 0;
  // flex-shrink: 0;

  &:hover {
  }
}

.list-item-content {
}
