/**
 * Share button
 *
 */

.btn-share {
  border-radius: $border-radius;
  width: 2em;
  height: 2em;
  line-height: 2em;
  margin-top: .25em;
  text-align: center;
  padding: 0;
  background: $hpz-old-silver;

  transition: all $duration-base $easing;

  .icon {
    color: $white;
    top: -.125em;
  }

  svg {
    fill: currentColor;
  }

  &:not(:last-child) {
    margin-right: .25em;
  }

  &.via-facebook { background: #3b5998; }
  &.via-twitter  { background: #55acee; }
  // &.via-whatsapp { background: #4dc247; }
  &.via-google   { background: #dd4b39; }
  // &.via-email    { background: #dd4b39; }

  &:hover {
    transform: scale(1.1);
  }
}