/**
 * Base class for our modules. Modules are living inside the `.content` element
 * and are the core elements of our pages.
 */

.module {
  display: block;
  width:   100%;
  margin-bottom: $spacing * 2;

  padding-left: $spacing * 2;
  padding-right: $spacing * 2;

  &:first-child:not(.module-hero) {
    margin-top: $spacing * 3;
  }

  @media screen and (max-width: $breakpoint-l){
    padding-left: $spacing;
    padding-right: $spacing;
  }

  @media screen and (max-width: $breakpoint-s){
    padding-left: $gutter;
    padding-right: $gutter;
  }

  @media screen and (max-width: $breakpoint-2xs){
    padding-left: 1em;
    padding-right: 1em;
  }
}

.module-title {
  margin-bottom: $spacing;
}
