/**
 * The Contact Teaser appears on every site above the footer.
 */

.module-contact-teaser {
  padding:        ($gutter * 3) ($gutter * 2);
  margin-bottom:  0;
  margin-top:     $gutter * 6;

  .breadcrumb-navigation + & {
    margin-top: 0;
  }

  background: linear-gradient(133deg, $hpz-marine 0%, rgba($hpz-marine, .6) 50%, rgba($hpz-marine, 0) 100%),
              url(../img/contact-teaser.jpg) no-repeat center center;
  background-size: cover;

  color: $white;

  .text-hl-s {
    color: $hpz-beige;
    margin-bottom: $gutter * 3;
  }

  @media screen and (max-width: $breakpoint-s) {
    padding-left: 1em;
    padding-right: 1em;
  }
}


.contact-teaser {
  a:not(.text-link) {
    color: currentColor;

    &:hover {
      color: currentColor !important;
      opacity: .75;
    }
  }
}
