figure {
  margin: 0;
}

img, svg, object, video, audio, iframe {
  display:        inline-block;
  vertical-align: top;
  max-width:      100%;
}

img, svg, object, video, audio {
  height: auto;
}