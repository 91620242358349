/**
 * Text module
 */

.module-text {
  width: 100%;
  max-width: 60em;

  margin-left: auto;
  margin-right: auto;

  h6 {
    font-weight: 600;
  }

  h6 {
    font-size: 1em;
    margin-bottom: 0;
  }

  & + &,
  & + .module-hint,
  .module-hint + & {
    margin-top: -1 * $spacing;
  }
}

.module-text.-wide {
  max-width: 70em;
}

.module-text.-full {
  max-width: none;
}

.module-text.-left {
  margin-left: 0;
}

.module-text.-right {
  margin-right: 0;
}
