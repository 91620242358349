/**
 * Main navigation element
 */

.main-navigation {
  z-index: $zindex-main-navigation;
}

.main-navigation-list {
  @media screen and (max-width: $breakpoint-hide-nav) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.main-navigation-item {
  text-align: center;
  margin-bottom: $header-border-height;

  &.is-active,
  &:hover {
    .main-navigation-link {
      color: $white;
    }

    &.main-navigation-item-marine .main-navigation-link {
      background: $hpz-marine;
    }
    &.main-navigation-item-grass .main-navigation-link {
      background: $hpz-grass;
    }
    &.main-navigation-item-forrest .main-navigation-link {
      background: $hpz-forrest;
    }
    &.main-navigation-item-tangerine .main-navigation-link {
      background: $hpz-tangerine;
    }
    &.main-navigation-item-sunshine .main-navigation-link {
      background: $hpz-sunshine;
    }
    &.main-navigation-item-oldsilver .main-navigation-link {
      background: $hpz-old-silver;
    }
    &.main-navigation-item-stonegrey .main-navigation-link {
      background: $hpz-stone-grey;
    }
    &.main-navigation-item-beige .main-navigation-link {
      background: $hpz-beige;
    }
  }

  &:not(:last-child) {
    margin-right: 0.25em;

    @media screen and (max-width: $breakpoint-hide-nav) {
      margin-right: 0;
    }
  }
}

.main-navigation-link {
  display: inline-block;
  text-decoration: none;
  letter-spacing: -0.01em;
  font-weight: $font-weight-bold;
  color: $white;
  padding: 0.5em 0.75em;
  transition: all 150ms ease-in-out;

  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background: rgba($black, 0.15);

  @media screen and (max-width: $breakpoint-hide-nav) {
    padding: 0.5em 0.75em;
    color: $hpz-stone-grey;
    background: none;
    border-radius: $border-radius;
  }
}
