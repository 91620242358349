/**
 * Copytext
 */

.copytext {
  a:not(.text-link) {
    color: inherit;
    text-decoration: underline;

    &:hover {
      @include colored('color', page);
    }
  }

  h1 {
    @extend .text-hl-xl;
  }

  h2 {
    @extend .text-hl-l;
  }

  h3 {
    @extend .text-hl-m;
  }

  h4 {
    @extend .text-hl-s;
  }

  h5 {
    @extend .text-hl-xs;
  }
}


/**
 * Copytext sizes.
 */

.text-copy-xs {
  font-size: $font-copy-xs;
}

.text-copy-s {
  font-size: $font-copy-s;
}

.text-copy-m {
  font-size: $font-copy-m;
}

.text-copy-l {
  font-size: $font-copy-l;
}

.text-copy-xl {
  font-size: $font-copy-xl;
}


/**
 * Reponsive text sizes
 */

@media screen and (max-width: $breakpoint-m){
  .on-m--text-copy-s {
    font-size: $font-copy-s;
  }
}
@media screen and (max-width: $breakpoint-s){
  .on-s--text-copy-s {
    font-size: $font-copy-s;
  }
}


/**
 * List styles.
 */
.copytext[class*="text-copy-"] {
  ul {
    padding-left: 1.5em;

    li {
      list-style: none;
      margin-bottom: $gutter / 2;

      &::before {
        content: '';
        display: block;
        background: url(../img/icons/arrow-right.svg) no-repeat center center;
        background-size: 100%;
        width: .8em;
        height: .8em;
        position: absolute;
        top: .75em;
        left: -1.5em;
        transform: translateY(-50%);
        opacity: .4;

        transition: opacity 100ms ease-in-out;
      }

      &:hover {
        &::before {
          opacity: .8;
        }
      }
    }
  }
}


/**
 * Text styles.
 */

.text-copy-info {
  color: $hpz-old-silver;
}

.text-link {
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  @include colored('color', page);

  transition: all 150ms ease-in-out;

  &:hover {
    color: currentColor;
  }
}

.text-info {
  text-transform: uppercase;
  color: $hpz-old-silver;
  font-weight: 500;
  letter-spacing: $font-kerning-s;
  line-height: 1.25;

  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

.text-caption {
  font-weight: 500;
  color: $hpz-old-silver;
  text-align: center;
}

.text-quote {

}

.text-strong {
  font-weight: 600;
}


/**
 * Font Feature Settings
 */

.text.with-lining-nums {
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}
