/**
 * Text Image Module
 */

 .module-text-image {
  display: flex;

  @media screen and (max-width: $breakpoint-2xs){
    display: block;
  }
}


.module-text-image-left {
  padding-left: 0;

  @media screen and (max-width: $breakpoint-2xs){
    padding-left: 1em;
  }

  .text-image-image {
    order: 1;
  }

  .text-image-text {
    order: 2;
    padding-left: $spacing * 2;

    @media screen and (max-width: $breakpoint-l){
      padding-left: $spacing;
    }

    @media screen and (max-width: $breakpoint-2xs){
      padding-left: 0;
      margin-top: $spacing;
    }
  }
}
.module-text-image-right {
  padding-right: 0;

  @media screen and (max-width: $breakpoint-2xs){
    padding-right: 1em;
  }

  .text-image-image {
    order: 2;
  }

  .text-image-text {
    order: 1;
    padding-right: $spacing * 2;

    @media screen and (max-width: $breakpoint-l){
      padding-right: $spacing;
    }

    @media screen and (max-width: $breakpoint-2xs){
      padding-right: 0;
      margin-top: $spacing;
    }
  }
}


.text-image-image {
  width: 100%;
}


.text-image-text {
  flex: 0 0 50%;

  [class^='text-'] {
    &:not(:first-child) {
      margin-top: #{$font-line-height}em;
    }

    &:not(:last-child) {
      margin-bottom: #{$font-line-height}em;
    }
  }
}
