.module-title {
  margin-bottom: $spacing * 1.5;

  max-width: 70em;

  &:not(:first-child) {
    margin-top: $spacing * 3;
  }

  .news-text &:first-child {
    margin-top: 0;
  }

  .module-hero + & {
    margin-top: 0;
  }
}
