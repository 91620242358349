/**
 * Tiles are ment to be used as little teaser items, displaying a bit of
 * information and providing a link to the full information article.
 * The whole tile is clickable, therefore it is ment to be used only on <a> tags
 * with an appropiate `href` link.
 *
 * Tiles can either be displayed full-width or next to each other in a grid.
 */

.tile {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: content-box;

  background: $beige;
  color: currentColor;
  text-decoration: none;

  border-radius: $border-radius;
  overflow: hidden;

  transition: all $duration-base $easing;

  &:hover {
    z-index: 10;
    box-shadow: 0 1em 2em rgba($black, 0.11), 0 0.33em 1em rgba($black, 0.08);
    transform: scale(1.01);
  }
}

.tile-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tile-vertical & {
    flex-direction: column;
  }
  .tile-horizontal & {
    flex-direction: row;

    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.tile-figure {
  width: 100%;
  overflow: hidden;

  .tile-horizontal & {
    order: 2;
    width: 100%;
  }
}

.tile-figure-image {
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tile-figure-placeholder {
  display: block;
  width: 100%;
  padding-top: #{(9/16) * 100%};
}

.tile-content {
  padding: $gutter;
  width: 100%;

  .tile-small & {
    padding: $gutter / 2;
  }

  .tile-horizontal.tile-medium & {
    padding: $gutter * 1.5;
  }

  .tile-horizontal.with-image & {
    order: 1;
    max-width: 30em;

    @media screen and (max-width: $breakpoint-xs) {
      max-width: none;
    }
  }
}

.tile-title {
  p {
    margin: 0;
  }
}

.tile-text {
  margin-top: $gutter / 2;

  .tile-horizontal.tile-medium & {
    margin-top: $gutter;
  }
}

.tile-details {
  margin-bottom: $gutter / 2;

  .tile-horizontal.tile-medium & {
    margin-bottom: $gutter;
  }
}

.tile-more {
}

.tile-icon {
  order: 3;
  padding: $gutter;
  color: $hpz-old-silver;
}
