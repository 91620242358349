.hint {
  padding: ($gutter / 1.5) $gutter;
  border-radius: $border-radius;
  border-left: $border-radius solid;

  h3 {
    font-weight: 600;
    font-size: 1em;
    margin: 0;

    font-variant-numeric: lining-nums;
    -moz-font-feature-settings: "lnum";
    -webkit-font-feature-settings: "lnum";
    font-feature-settings: "lnum";

    ~ p {
      margin-top: 0;
    }
  }

  &.as-hint {
    background: $yellow-light;
    border-left-color: $yellow-normal;
    color: $yellow-dark;
  }

  &.as-warning {
    background: $red-light;
    border-left-color: $red-normal;
    color: $red-dark;
  }

  &.as-advice {
    background: $green-light;
    border-left-color: $green-normal;
    color: $green-dark;
  }

  &.as-info {
    // background: $blue-light;
    // border-left-color: $blue-normal;
    // color: $blue-dark;

    background: $hpz-beige;
    border-left-color: $hpz-old-silver;
    color: $hpz-stone-grey;
  }
}
