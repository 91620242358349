$image-gallery-gutter: $gutter / 2;


.module-images {
  padding: $image-gallery-gutter;
}

.images-gallery {
  display: flex;

  .image-in-gallery {
    display: block;
    border-radius: $border-radius;
    overflow: hidden;
  }

  figure:not(:last-child) {
    margin-bottom: $image-gallery-gutter;
  }
}

.images-col {
  width: 100%;

  & + & {
    margin-left: $image-gallery-gutter;
  }
}
