/**
 * Headlines
 */

.text-hl-xl {
  font-size: $font-hl-xl;
  line-height: $font-hl-xl-lh;
  font-family: $font-family-serif;
  font-weight: 700;

  @include colored("color", page);

  @media screen and (max-width: $breakpoint-3xs) {
    font-size: $font-size-3xl;
  }
}

.text-hl-l {
  font-size: $font-hl-l;
  line-height: $font-hl-l-lh;
  font-family: $font-family-serif;
  font-weight: 700;
}

.text-hl-m {
  font-size: $font-hl-m;
  line-height: $font-hl-m-lh;
  font-weight: $font-weight-bold;
}

.text-hl-s {
  font-size: $font-hl-s;
  font-weight: 600;
  letter-spacing: $font-kerning-l;
  text-transform: uppercase;
  color: $hpz-old-silver;
  margin-bottom: 0.5em;
}

.text-hl-xs {
  font-size: $font-hl-xs;
  font-weight: 600;
  letter-spacing: $font-kerning-l;
  text-transform: uppercase;
  color: $hpz-old-silver;
  margin-bottom: 1em;
}

[class^="text-hl-"] {
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

[class^="text-hl-"] a {
  color: currentColor;
  text-decoration: none;
}
