/**
 * Wrapper
 */

.wrapper {
  width:         100%;
  padding-left:  $spacing;
  padding-right: $spacing;

  @media screen and (max-width: $breakpoint-l){
    padding-left: $spacing / 2;
    padding-right: $spacing / 2;
  }

  @media screen and (max-width: $breakpoint-2xs){
    padding-left: 1em;
    padding-right: 1em;
  }
}