.person {
  display: flex;
  align-items: center;
  margin-top: $gutter;
}

.person-image {
  width: 12em;
  margin-right: $gutter * 2;
  flex-grow: 0;
  flex-shrink: 0;

  @media screen and (max-width: $breakpoint-xs) {
    margin-right: $gutter;
    width: 10em;
  }
}

.person-text {
  width: 100%;
}
