/**
 * Hero module as parent and wrapping element for the hero image or gallery.
 */

.module-hero {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;

  @media screen and (max-width: $breakpoint-l) {
    margin-bottom: $gutter;
  }

  @media screen and (max-width: $breakpoint-2xs) {
    margin-bottom: $gutter * 2;
  }
}

/**
 * Hero
 */

.hero {
}

.hero-figure {
  --x: 50%;
  --y: 50%;

  img {
    object-fit: cover;
    object-position: var(--x) var(--y);
    width: 100%;

    $height: $hero-height;

    .hero.is-larger & {
      height: $height * 1.25;
    }
    .hero.is-large & {
      height: $height;
    }
    .hero.is-medium & {
      height: $height * 0.75;
    }
    .hero.is-small & {
      height: $height * 0.5;
    }
    .hero.is-tiny & {
      height: $height * 0.4;
    }

    @media screen and (max-width: $breakpoint-l) {
      $height: $hero-height * 0.8;

      .hero.is-larger & {
        height: $height * 1.25;
      }
      .hero.is-large & {
        height: $height;
      }
      .hero.is-medium & {
        height: $height * 0.75;
      }
      .hero.is-small & {
        height: $height * 0.5;
      }
      .hero.is-tiny & {
        height: $height * 0.4;
      }
    }

    @media screen and (max-width: $breakpoint-m) {
      $height: $hero-height * 0.75;

      .hero.is-larger & {
        height: $height * 1.25;
      }
      .hero.is-large & {
        height: $height;
      }
      .hero.is-medium & {
        height: $height * 0.75;
      }
      .hero.is-small & {
        height: $height * 0.5;
      }
      .hero.is-tiny & {
        height: $height * 0.4;
      }
    }

    @media screen and (max-width: $breakpoint-2xs) {
      $height: $hero-height * 0.5;

      .hero.is-larger & {
        height: $height * 1.25;
      }
      .hero.is-large & {
        height: $height;
      }
      .hero.is-medium & {
        height: $height * 0.75;
      }
      .hero.is-small & {
        height: $height * 0.5;
      }
      .hero.is-tiny & {
        height: $height * 0.4;
      }
    }
  }
}

/**
 * The Hero Border adds the little white inset on the bottom of our hero. This
 * way we don't have to deal with negative margins and edge cases – just insert
 * the border, if we use the hero, done.
 */

.hero-border-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.hero-border {
  background: $white;
  height: $gutter * 2;
  width: 100%;

  @media screen and (max-width: $breakpoint-l) {
    height: $gutter;
  }

  @media screen and (max-width: $breakpoint-2xs) {
    display: none;
  }
}
