/**
 * Service navigation item
 */

 .service-navigation-list {

}

.service-navigation-item {
  &:not(:last-child) {
    padding-right: .1em;
  }
}

.service-navigation-link,
.service-navigation-text {
  display: inline-block;
  text-decoration: none;
  color: inherit;

  padding: .25em .5em;
  border-radius: $border-radius;
  font-weight: 600;
}

.service-navigation-link {
  transition: all 120ms ease-in-out;

  &:hover {
    background: rgba($hpz-stone-grey, .3);
  }
}
